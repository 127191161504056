<script setup lang="ts">
const { t } = useI18n()
import { debounce } from 'lodash'
import { apiStore } from '~/stores/api'
import { unwrapApiErrors } from '~/types/api'
import type { ToastList } from '~/types/toast'
import { Supplier } from '~/types/view-elements'
import { unwrapRouteParam } from '~/utils/route'

const api = apiStore().getApiClient
const router = useRouter()
const route = useRoute()

const state = reactive<{
  suppliers: Supplier[]
  isLoading: boolean
  isLoadingMore: boolean
  currentPage: number
  perPage: number
  hasFetchingNext: boolean
  observer: IntersectionObserver | null
  supportedIntersectObserver: boolean
  isReachedAtLast: boolean
  hasAddSupplierModalOpen: boolean
  hasAddingSupplier: boolean
  hasDeleteSupplierModalOpen: boolean
  isDeletingSupplier: boolean
  search: string
  status: string
  toast?: ToastList
  selectedSuppliersForDelete: string[]
  imagePath: string | null
  isFullscreenImageModalOpen: boolean
  canCreateSupplier: boolean
  selectAll: boolean
}>({
  suppliers: [],
  isLoading: false,
  isLoadingMore: false,
  currentPage: 1,
  perPage: 15,
  hasFetchingNext: false,
  observer: null,
  supportedIntersectObserver: false,
  isReachedAtLast: false,
  hasAddSupplierModalOpen: false,
  hasAddingSupplier: false,
  hasDeleteSupplierModalOpen: false,
  isDeletingSupplier: false,
  search: '',
  status: unwrapRouteParam(route.query.status) || '',
  toast: inject<ToastList>('toast'),
  selectedSuppliersForDelete: [],
  imagePath: null,
  isFullscreenImageModalOpen: false,
  canCreateSupplier: false,
  selectAll: false,
})

// Observer & Un-observe
function registerObserver() {
  let options = {
    rootMargin: '0px',
    root: null,
  }

  state.observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadPaginatedData(state.currentPage, state.perPage)
      }
    })
  }, options)

  let target = document.querySelector('#issueObserver')
  if (target) {
    state.observer.observe(target)
  }
}

function unRegisterObserver() {
  let target = document.querySelector('#issueObserver')
  if (target && state.observer) {
    state.observer.unobserve(target)
    state.observer = null
  }
}

async function loadPaginatedData(page: number = 1, perPage: number = 15) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  if (page === 1) {
    state.isLoading = true
  } else {
    state.isLoadingMore = true
  }
  state.hasFetchingNext = true

  try {
    const response = await api.getSuppliers(page, perPage, {
      name: state.search,
      status: state.status,
    })

    if (response?.data) {
      state.suppliers.push(...response.data)

      if (response.meta.current_page == response.meta.last_page) state.isReachedAtLast = true

      if (response?.auth?.can?.create) state.canCreateSupplier = response?.auth?.can?.create

      state.currentPage += 1
      state.hasFetchingNext = false
    }
  } catch (error) {
    router.push('/')
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    if (page === 1) {
      state.isLoading = false
    } else {
      state.isLoadingMore = false
    }
  }
}

function goToSupplierDetails(row: Supplier) {
  if (!row.auth.can.view) {
    return
  }

  router.push(`/sourcing/${row.id}`)
}

function openAddSupplierModal() {
  state.hasAddSupplierModalOpen = true
}

function closeAddSupplierModal() {
  state.hasAddSupplierModalOpen = false
}

function createSupplier(data: Supplier) {
  state.suppliers.unshift(data)
}

const searchTextChanged = debounce(async () => {
  state.currentPage = 1
  state.isReachedAtLast = false
  state.suppliers = []
  state.selectAll = false
  state.selectedSuppliersForDelete = []

  await loadPaginatedData(1)
}, 400)

function appendOrRemoveForDelete(supplier: Supplier) {
  if (state.selectedSuppliersForDelete.includes(supplier.id)) {
    const idx = state.selectedSuppliersForDelete.findIndex(e => e === supplier.id)
    state.selectedSuppliersForDelete.splice(idx, 1)
  } else {
    state.selectedSuppliersForDelete.push(supplier.id)
  }
}

function openDeleteSupplierModal() {
  state.hasDeleteSupplierModalOpen = true
}

function closeDeleteSupplierModal() {
  state.hasDeleteSupplierModalOpen = false
}

async function deleteSuppliers() {
  try {
    state.isDeletingSupplier = true

    for (const supplierId of state.selectedSuppliersForDelete) {
      const idx = state.suppliers.findIndex(e => e.id === supplierId)
      if (idx !== -1) {
        await api.deleteSupplier(supplierId)
        state.suppliers.splice(idx, 1)
      }
    }

    state.selectedSuppliersForDelete = []
    state.selectAll = false

    state.toast?.success(t('global.success'), t('suppliers.delete_success'))
    closeDeleteSupplierModal()
  } catch (error) {
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    state.isDeletingSupplier = false
  }
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

function selectOrUnselectSuppliers(selectAll: boolean) {
  if (selectAll) {
    state.selectedSuppliersForDelete = state.suppliers.map(supplier => supplier.id)
  } else {
    state.selectedSuppliersForDelete = []
  }
}

/** Permission functions start */
const canCreateSupplier = computed(() => {
  return state.canCreateSupplier
})
/** Permission functions end */

onMounted(async () => {
  if (!window.IntersectionObserver) {
    state.supportedIntersectObserver = false
  } else {
    state.supportedIntersectObserver = true
  }

  await loadPaginatedData(1)

  window.addEventListener('keyup', e => {
    if (e.key === 'Escape') {
      closeAddSupplierModal()
    }
  })
})

watch(
  () => state.suppliers.length,
  () => {
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal) {
      unRegisterObserver()
    }
  },
)

const tabs = [
  { key: 'type', label: t('global.type'), maxWidth: '72px' },
  { key: 'name', label: t('global.name') },
  { key: 'image', label: t('global.image') },
  { key: 'status', label: t('global.status') },
  { key: 'description', label: t('global.description') },
  { key: 'updated_at', label: t('global.last_updated'), type: 'date' },
]

function rowSelect(row: Supplier | boolean) {
  if (typeof row === 'boolean') {
    selectOrUnselectSuppliers(row)
  } else {
    appendOrRemoveForDelete(row)
  }
}
</script>

<template>
  <div class="flex flex-1 flex-col gap-2 px-6 pt-6">
    <div class="flex gap-2 mb-4">
      <div class="flex gap-2 mr-auto">
        <OSearchBar
          v-model="state.search"
          :placeholder="`${$t('global.search')}`"
          class="!w-96"
          @update:modelValue="searchTextChanged"
        />
      </div>
      <div class="flex">
        <button
          class="btn-danger flex items-center gap-2 first:mr-2"
          v-if="state.selectedSuppliersForDelete.length > 0"
          @click="openDeleteSupplierModal"
        >
          <div i="carbon-trash-can" />
          {{ $t('global.delete') }}
        </button>
        <button class="btn-primary h-full" @click="openAddSupplierModal" v-if="canCreateSupplier">
          <div i="carbon-intent-request-create" />
          {{ $t('global.new') }}
        </button>
      </div>
    </div>

    <!-- Table -->
    <CTable
      :columns="tabs"
      :rows="state.suppliers"
      :is-loading="state.isLoading"
      :is-loading-more="state.isLoadingMore"
      :emptyMessage="$t('entities.no_suppliers')"
      @row-select="rowSelect"
      @row-click="goToSupplierDetails($event)"
    >
      <template #type="{ item: supplier }">
        <div class="-my-2">
          <EntityIcon :entity="supplier" :tooltip="true" />
        </div>
      </template>

      <template #image="{ item: supplier }">
        <div class="col-span-1 max-h-10 overflow-hidden rounded" @click="openFullScreenImageModal(supplier?.image)">
          <img :src="supplier.image" v-if="supplier?.image" class="object-contain w-[42px] h-auto aspect-square" />
        </div>
      </template>

      <template #status="{ item: supplier }">
        <div class="flex">
          <PublishingStatusTag :status="supplier.status" />
        </div>
      </template>
    </CTable>

    <div class="pt-6" id="issueObserver" />

    <SupplierCreationModal
      :open="state.hasAddSupplierModalOpen"
      default-type="Project"
      @close="closeAddSupplierModal"
      @create="createSupplier"
    />

    <!-- Delete Modal -->
    <OModal :open="state.hasDeleteSupplierModalOpen">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('suppliers.delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('suppliers.delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.isDeletingSupplier" @click="closeDeleteSupplierModal">
            {{ t('global.cancel') }}
          </button>
          <button
            class="btn-danger bg-red-500 text-white"
            autofocus
            :disabled="state.isDeletingSupplier"
            @click="deleteSuppliers"
          >
            {{ state.isDeletingSupplier ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>
  </div>
</template>
